import Utils from "../../utils";
import { getIntervalText, getScheduleIntervalText, Intervals, type WeekDays, type Months, getScheduledAt} from "./intervals";
import {getTodayDate} from "../../time_helpers/time_helpers";

export interface ISchedule {
  id: number | null,
  custom_report_id: number | null,
  run_times: number,
  interval: Intervals,
  interval_period: number,
  options: IScheduleOptions
  start_date: string,
  end_date: string,
  new_record?: string,
  schedule_recipients: any[]
  _destroy?: string
  email_content: string
  timezone: string
  scheduled_at?: string
}


export interface IScheduleOptions {
  day_time: IDayTime,
  month_day?: number,
  week_days: WeekDays[],
  months: Months[]
}

export interface IDayTime {
  hour: number,
  minute: number
}

const DEFAULT_SCHEDULE: ISchedule = {
  id: null,
  custom_report_id: null,
  run_times: 0,
  interval: Intervals.once,
  interval_period: 1,
  options: {
    day_time: {
      hour: 17,
      minute: 0
    },
    month_day: undefined,
    week_days: [],
    months: []
  },
  start_date: '',
  end_date: '',
  schedule_recipients: [],
  email_content: '',
  timezone: typeof window !== 'undefined' ? window.userTimeZone : 'UTC',
}

export const createDefaultSchedule = () => {
  const defaultSchedule = Utils.deepCopy(DEFAULT_SCHEDULE)
  defaultSchedule.start_date = getTodayDate('YYYY-MM-DD') as string
  defaultSchedule.end_date = getTodayDate('YYYY-MM-DD') as string
  defaultSchedule.options.month_day = getTodayDate('D') as number
  return defaultSchedule
}

export const getScheduleText = (schedule: ISchedule): string => `${schedule.interval == Intervals.once ? 'Once' : 'Every'} ${getIntervalText(schedule.interval)} ${getScheduleIntervalText(schedule)} ${getScheduledAt(schedule)}`

export const denormalizeSchedule = (schedule: ISchedule, report_id: number): any => {
  const scheduleCopy = {...schedule} as any
  scheduleCopy.options = JSON.stringify(schedule.options)
  scheduleCopy.custom_report_id = report_id
  scheduleCopy.schedule_recipients_attributes = [...(scheduleCopy.schedule_recipients ?? []).map((recipient: any) => {
    delete recipient.new_record
    return recipient
  })]
  delete scheduleCopy.schedule_recipients
  return scheduleCopy
}

export const normalizeSchedule = (schedule: any, report_id?: number): ISchedule => {
  const scheduleCopy = {...schedule} as any
  scheduleCopy.options = JSON.parse(schedule.options)
  if(report_id) scheduleCopy.custom_report_id = report_id
  scheduleCopy.interval = Intervals[scheduleCopy.interval]
  return scheduleCopy
}

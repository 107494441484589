import { Query } from '@avvoka/editor'
import type { Blot, MountedEditor } from '@avvoka/editor'
import type { AstBox } from './ast/ast'
import Ast from './ast/ast'

/**
 * A constant that represents the absence of a visibility condition.
 */
export const NO_VIS_COND = undefined
export const NO_VIS_COND_HUMAN = globalThis?.localizeText?.(
  'questionnaire.vis_cond.no_vis_cond'
)

/**
 * This function is used to get the visibility condition for a given question.
 * It first checks if the question has an attribute, if not it returns a constant NO_VIS_COND.
 * If the question has an attribute, it finds the attribute in the document and gets the visibility condition for each attribute.
 * If any of the visibility conditions are null or if there are no visibility conditions, it returns NO_VIS_COND.
 * Otherwise, it combines all the visibility conditions using an 'Or' operator and returns the result as a string.
 *
 * @param question The question for which to get the visibility condition.
 * @param editor The editor instance in which to find the attribute.
 * @return The visibility condition for the question as a string, or undefined if no visibility condition is found.
 */
export function getVisibilityConditionForQuestion(
  question: Backend.Questionnaire.IQuestion,
  editor: MountedEditor
): string | undefined {
  if (!question.att) return NO_VIS_COND
  const attributeInDocument = findAttributeInDocument(question.att, editor)
  const separateAsts = attributeInDocument.map((blot) =>
    getVisibilityConditionForAttribute(blot)
  )
  const shouldBeNull =
    separateAsts.some((ast) => !ast) || separateAsts.length === 0
  if (shouldBeNull) return NO_VIS_COND

  const combinedAst = combineAST(separateAsts as AstBox[], 'Or')
  if (!combinedAst) return undefined
  return Ast.stringify(combinedAst)
}

/**
 * This function is used to get the visibility condition for a given attribute.
 * It first finds all the parent conditions for the attribute.
 * Then, it parses each condition into an Abstract Syntax Tree (AST) and stores them in an array.
 * If there are no parent conditions, it returns null.
 * Otherwise, it combines all the ASTs using an 'And' operator and returns the result.
 *
 * @param att The attribute for which to get the visibility condition.
 * @returns The visibility condition for the attribute as an AST, or null if no visibility condition is found.
 */
export function getVisibilityConditionForAttribute(
  att: Blot
): AstBox | undefined {
  const allParentConditions = findAllParentConditions(att).map((blot) =>
    Ast.parse(blot.attributes['data-condition'])
  ) as AstBox[]
  const isNested = allParentConditions.length !== 0
  if (!isNested) return undefined
  else return combineAST(allParentConditions, 'And')
}

function findAllParentConditions(blot: Blot): Array<Blot> {
  const lookingFor = ['condition', 'icondition']
  // slice is used to not include itself in the path
  const path = blot.path().slice(0, -1)
  return path.filter((blot) => {
    return lookingFor.includes(blot.statics.blotName)
  })
}

function findAllAttributes(editor: MountedEditor): Array<Blot> {
  return editor.query(
    Query<Blot>('placeholder', 'condition', 'icondition')
  ) as Blot[]
}

function findAttributeInDocument(
  attributeName: string,
  editor: MountedEditor
): Blot[] {
  const allAttributesBlots = findAllAttributes(editor)
  return allAttributesBlots.filter((attributeObj) => {
    if (attributeObj.statics.blotName !== 'placeholder') {
      const parsedAst = Ast.parse(attributeObj.attributes['data-condition'])
      if (!parsedAst) return false
      const attributes = Ast.traverse(parsedAst).attributes
      return attributes.includes(attributeName)
    } else {
      const attribute = attributeObj.node?.textContent
      return attribute === attributeName
    }
  })
}

function combineAST(
  asts: AstBox[],
  logicOperator: 'And' | 'Or'
): AstBox | undefined {
  // If there is only one AST or zero, return it or undefined
  if (asts.length <= 1) return asts[0]

  let fullAst: AstBox = { ast: { [logicOperator]: [] } }

  for (let item of asts) {
    // If some of the items are undefined, return undefined
    if (item == undefined) {
      return undefined
    }

    const unboxedAst = Ast.unbox(item)

    if (
      fullAst.ast[logicOperator]!.some((ast) => Ast.compare(ast, unboxedAst))
    ) {
      continue
    }

    fullAst.ast[logicOperator]!.push(unboxedAst)
  }

  if (fullAst.ast[logicOperator]?.length === 1) {
    fullAst.ast = fullAst.ast[logicOperator]![0]
  }

  return fullAst
}
